body {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #e9e9e9 0%, #bababa 100%);
  /* overflow: hidden; */
  padding: 0;
  margin: 0;
  /* display: flex; */
  justify-content: center;
  align-items: center;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to bottom, #e0e0e0, #ffffff); */
}

.login {
  overflow: hidden;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* bottom: 0; */
  /* width: 300px; */
  /* height: 400px; */
  border-radius: 10px;
  padding: 20px;
  background: #fff;
}

/* Invoices */
.invoices-table {
  padding: 4px!important;
}